import React, { useState } from "react";
import "./App.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { GoogleRecaptchaWrapper } from "./hooks/GoogleRecaptchaWrapper";
import moment from 'moment';

function MyVerticallyCenteredModal(props: any) {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [error, setError] = useState(false);

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
      return;
    }

    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available!");
      return;
    }

    const gReCaptchaToken = await executeRecaptcha("contactForm");
    setLoading(true);

    try {
      const data = {
        email: email,
        type: props.type,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}lbr/add`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            recaptcha: gReCaptchaToken,
          },
        }
      );

      if (response?.data?.code === 201) {
        setError(false);
        setEmail("");
        setMessage("You have successfully subscribed to the waitlist");
      } else {
        setError(true);
        setMessage(response?.data?.message);
      }
    } catch (error: any) {
      if (error.response) {
        setError(true);
        setMessage(error?.response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setEmail("");
    setMessage("");
    setLoading(false);
    setEmailError("");
    setError(false);
    props.onHide();
  };

  return (
    <>
      <Modal
        className="custome-model"
        data-bs-theme="dark"
        size="md"
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header className="custome-header" closeButton>
          <Modal.Title id="contained-modal-title-vcenter costume-title">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="costume-body">
          <form onSubmit={handleSubmit}>
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="text"
              id="email"
              className={`form-input custome-input ${
                emailError ? "error-form" : ""
              }`}
              placeholder="Enter your email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError("");
              }}
              autoComplete="off"
            />
            <button
              type="submit"
              className="form-button custome-button"
              disabled={loading}
            >
              {loading ? "Please wait..." : "Submit"}
            </button>
          </form>
          {message && (
            <p
              id="message"
              className={`form-message ${error ? "error" : "success"}`}
            >
              {error ? (
                <img src="/close.svg" alt="success" />
              ) : (
                <img src="/tabler-icon-circle-check.svg" alt="success" />
              )}
              {message}
            </p>
          )}

          {emailError && (
            <p
              id="message"
              className={`form-message ${emailError ? "error" : "success"}`}
            >
              <img src="/close.svg" alt="success" />
              {emailError}
            </p>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

function App() {
  const [modalShow, setModalShow] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [type, setType] = React.useState("");

  return (
    <>
      <header>
        <div className="img-logo">
          <img src="/lbrlogo.svg" alt="logo" />
        </div>
      </header>

      <main>
        <section>
          <div className="video-background">
            <video autoPlay muted loop playsInline>
              <source src="/lbr-bg.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="video-background-hidden">
            <video autoPlay muted loop playsInline>
              <source src="/lbr-bg-responsive.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="container">
            <div className="header-main">
              <div className="header-titles">
                <h1 className="header-text">
                  Get ready! We're redefining word-of-mouth marketing by
                  transforming your social influence into real rewards.
                </h1>
              </div>
              <div className="notify-box">
                <p className="notify-title">Notify me</p>
                <div className="button-main">
                  <Button
                    className="btn-clr"
                    type="button"
                    onClick={() => {
                      setTitle("Join the brand's waitlist");
                      setModalShow(true);
                      setType("brand");
                    }}
                  >
                    I'm a Brand
                  </Button>
                  <GoogleRecaptchaWrapper>
                    <MyVerticallyCenteredModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                      title={title}
                      type={type}
                    />
                  </GoogleRecaptchaWrapper>
                  <Button
                    className="btn-clr"
                    type="button"
                    onClick={() => {
                      setTitle("Join the creator's waitlist");
                      setModalShow(true);
                      setType("creator");
                    }}
                  >
                    I'm a Creator
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer className="footer-main">
        <p className="footer-text">© { moment().format('yyyy') } BeRaw, All Rights Reserved</p>
      </footer>
    </>
  );
}

export default App;
